@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600&family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;600&family=Merriweather:wght@700&family=Roboto:wght@100&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

div#root {
  height: 100vh !important;
}

html {
  background-color: #f0f0f0 !important;
}

body {
  height: 100vh;
  font-family: 'Inter', sans-serif;
  background-color: #f0f0f0 !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.h1 {
  font-size: 2.5rem !important;
}

input,
select,
textarea,
button {
  padding: 8px !important;
  /* border-radius: 4px !important; */
  border: 1px #b1b9ce solid;
  font-size: 1rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::placeholder {
  color: #a3a3a3;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif, FontAwesome !important;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #2f427c 50%),
    linear-gradient(135deg, #2f427c 50%, transparent 50%),
    radial-gradient(#ffffff 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(0.8em + 2px), calc(100% - 15px) calc(0.8em + 2px),
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

button {
  background-color: #f8f8f8;
  cursor: pointer;
  padding: 7px 30px !important;
  color: #333 !important;
}

input:hover,
select:hover,
textarea:hover,
button:hover {
  /* border: 1px #2f427c solid; */
}

input:focus-visible {
  outline-offset: 0 !important;
  outline: 0 !important;
}

select:focus {
  /* border: 1px #b1b9ce solid !important; */
  border: none !important;
}

select:active {
  border: 1px #b1b9ce solid !important;
}

input:focus,
textarea:focus,
button:focus {
  border: 1px #2878fa solid !important;
}

/* label.primary {
  color: #2f427c !important;
} */
.primary {
  background-color: #eee;
  border: 1px solid #ddd;
  color: #333;
  font-size: 1rem;
  font-size: medium;
  padding: 10px 22px !important;
  border-radius: 200px;
  transition: transform 450ms;
}
.btnbg-bkup {
  background-color: #2878fa !important;
  color: white;
}

button.primary:hover {
  -ms-transform: scale(1.04);
  /* IE 9 */
  -webkit-transform: scale(1.04);
  /* Safari 3-8 */
  transform: scale(1.04);
  color: #fff !important;
  background-color: #2878fa !important;
}

button.danger {
  background-color: #e92c2c !important;
  color: #fff !important;
}

button.danger:hover {
  /* background-color: #586eb0 !important; */
  -ms-transform: scale(1.04);
  /* IE 9 */
  -webkit-transform: scale(1.04);
  /* Safari 3-8 */
  transform: scale(1.04);
}
.primary {
  /* background-color: #2f427c !important;
  color: #fff !important; */
  background-color: #eee;
  border: 1px solid #ddd;
  color: #333;
  font-size: 1rem;
  font-size: medium;
  padding: 10px 22px !important;
  border-radius: 200px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.align-center-common {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.box {
  padding: 20px;
  background-color: #fff;
}

.box-p20-t10 {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

.box-header-logo {
  padding-right: 20px;
  background-color: #fff;
}

.sideImage {
  width: 100%;
  max-width: 150px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: normal;
  align-items: center;
}

.row.top {
  align-items: flex-start !important;
}

.row.center {
  justify-content: center;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

/* textarea {
  max-width: 35rem !important;
} */

img.small {
  width: 100%;
  max-width: 5rem;
}

img.small-logo {
  width: 100%;
  max-width: 4.8rem;
}

img.medium {
  width: 100%;
  max-width: 8rem;
}

img.large {
  max-width: 700px;
  width: 100%;
}

img.settings {
  width: 70%;
  max-width: 3.5rem;
  padding: 6px;
  color: #2f427c;
  border-radius: 50px;
  border: solid #2f427c 1px;
}

.v-top {
  margin-top: 0px !important;
  padding-top: 0px !important;
  display: inline-block;
  vertical-align: top;
  /* height: 1098px; */
}

.v-top-edit {
  margin-top: 0px !important;
  padding-top: 0px !important;
  display: inline-block;
  vertical-align: top;
  height: 940px;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-right {
  display: flex;
  justify-content: flex-end;
}

.justify-space-around {
  display: flex;
  justify-content: space-around;
}

.justify-space-between {
  display: flex;
  justify-content: space-between;
}

.justify-left {
  display: flex;
  justify-content: left;
}

.heading {
  font: size 24px;
  color: #444444;
}

.div-label {
  color: #676767 !important;
  font-size: 16px !important;
}

.div-label-small {
  color: #676767 !important;
  font-size: 13px !important;
}

.font-bold {
  font-weight: 700 !important;
}

.form-group {
  margin-bottom: 1rem !important;
}

.form-group input {
  min-width: 100px;
}

.mt-2px {
  margin-top: 2px !important;
}

.mt-5px {
  margin-top: 5px !important;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-15px {
  margin-top: 15px !important;
}

.mt-20px {
  margin-top: 20px !important;
}

.mt-25px {
  margin-top: 25px !important;
}

.mt-33 {
  margin-top: 33px !important;
}

.mb-10px {
  margin-bottom: 10px !important;
}
.m8 {
  margin: -8px;
}

.mb-7px {
  margin-bottom: 7px !important;
}

.mb-20px {
  margin-bottom: 20px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-30px {
  margin-bottom: 30px !important;
}

.mb-35px {
  margin-bottom: 35px !important;
}

.mr-30px {
  margin-right: 30px !important;
}

.w-100px {
  width: 100% !important;
}

.w-150pix {
  width: 150px !important;
}

.w-300pix {
  width: 300px !important;
}

.w-90perc {
  width: 90% !important;
}

.mb-50px {
  margin-bottom: 50px !important;
}

.mb-100px {
  margin-bottom: 100px !important;
}

.ml-2px {
  margin-left: 2px;
}

.ml-5px {
  margin-left: 5px;
}

.ml-9px {
  margin-left: 9px;
}

.ml-10px {
  margin-left: 10px;
}

.mr-10px {
  margin-right: 10px;
}

.ml-20px {
  margin-left: 20px;
}

.ml-27px {
  margin-left: 27px;
}

.ml-30px {
  margin-left: 30px;
}

.ml-100px {
  margin-left: 100px;
}

.copy-link-button-width {
  min-width: 285px;
  max-width: 285px;
}

@media screen and (max-width: 700px) {
  #sideImage {
    display: none;
  }
}

.font-8px {
  position: absolute;
  font-size: 8px !important;
  bottom: 0;
}

/*header*/

.header-nav-fixed {
  position: fixed;
  background-color: #1e3343;
  padding-top: 5px !important;
  padding-left: 20px !important;
  width: 100%;
  z-index: 2;
}

.header {
  background-color: #1e3343;
  position: sticky;
  padding-top: 5px !important;
  padding-left: 20px !important;
  width: 100%;
  z-index: 100 !important;
}

.content_container-register {
  padding: 0px 20px 0 20px;
}

.content_container {
  background-color: #f0f0f0;
  /*  height: 100vh;  TODO */
  padding: 0px 20px 0 20px;
}

.header-content-container {
  background-color: #f0f0f0;
  padding: 20px 20px;
}

.main_select {
  display: flex;
  align-items: center;
}

.ml-54px {
  margin-left: 54px;
}

.table {
  margin-top: 10px;
  width: 100%;
}

.table thead tr th {
  background-color: #ebecf4;
  color: #3d414d;
  padding: 10px;
  text-align: left;
  border: 1px solid #e0e0e0;
}

.table tbody tr td {
  padding: 10px;
  border: 1px solid #e0e0e0;
}

.table tbody tr td a {
  text-decoration-line: none;
}

a {
  cursor: pointer;
}

a.none {
  text-decoration: none !important;
  color: #303030;
}

a.primary {
  color: #2f427c !important;
}

.table tbody tr:hover td a.none {
  text-decoration: underline !important;
  color: #0d6efd !important;
}

.table tbody tr:hover {
  background-color: #f9fafc;
}

.table tbody tr:hover td label {
  color: #2f333c;
}

.table tbody tr td span a {
  text-decoration: none;
  color: #707070 !important;
}

.table tbody tr:hover td span a {
  text-decoration: underline !important;
  color: #2f427c !important;
}

.table tbody tr:hover a {
  text-decoration-line: underline;
}

.pagination {
  margin: 10px 0;
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  margin: 0 5px;
  border: 1px solid #dddd;
  text-decoration: none;
}

.div-left {
  float: left !important;
}

.div-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-center-margin-auto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.div-right {
  float: right !important;
}

.user-select {
  padding-top: 10px !important;
  padding-right: 22px !important;
}

.nav-hotel-logo-right {
  padding-top: 8px !important;
  padding-bottom: 8px;
  margin-right: 20px;
  /* padding-right: 45px !important; */
}

.hotel-logo-right {
  padding-right: 22px !important;
}

.success {
  color: #00ba34 !important;
}

.danger {
  color: #e92c2c !important;
}

.content_header {
  background-color: #fff;
  padding: 20px;
}

.content_header-no-bp {
  background-color: #ebecf4;
  border: 1px solid #dddd;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: -5px;
}

.content-header-setting {
  background-color: #ebecf4;
  border: 1px solid #dddd;
  padding: 20px 20px 7px 20px;
}

.color-light-grey {
  color: #676767 !important;
}

.color-grey {
  color: #9699a0 !important;
}

.font-merri {
  font-family: 'Merriweather', serif !important;
  color: #676767 !important;
}

.bluebackGround {
  background-color: #2f427c !important;
  /* background-color:  #2878fa !important; */
}

.resetpass-label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}
.nav_img {
  height: 70px;
  margin-top: 5px;
  color: #fff !important;
  /* background-color: #fff; */
}

.nav_links {
  display: flex;
  /* justify-content: normal; */
  /* justify-content: space-evenly; */
  align-items: center;
  margin-left: 20px;
  font-size: 19px;
}

.tipinp {
  text-align: right !important;
}

.nav_links a {
  position: relative;
  text-decoration: none;
  color: #fff !important;
  font-weight: bold;
  margin: 0 20px;
}

/* .nav_links a:hover {
  color: #2f427c !important;
  border-bottom: 3px solid #2f427c;
} */

.nav_links a.active {
  border-bottom: 2px inset #2878fa;
  color: #2878fa;
}

.nav_links a:after {
  content: '';
  position: absolute;
  background-color: #2878fa;
  height: 2px;
  width: 0;
  left: 0;
  top: 29px;
  transition: 0.3s;
}

.nav_links a:hover:after {
  width: 100%;
}
.rounded-btn {
  color: #fff !important;
  border: 1px solid #ddd;
  font-size: 1rem;
  font-size: medium;
  padding: 10px 22px !important;
  border-radius: 200px;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.margin-right-15px {
  margin-right: 15px !important;
}

.margin-right-25px {
  margin-right: 25px !important;
}

.margin-right-35px {
  margin-right: 35px !important;
}

.margin-right-0px {
  margin-right: 0px !important;
}

.margin-right-40px {
  margin-right: 40px !important;
}

.margin-right-5px {
  margin-right: 5px !important;
}

.margin-left-5px {
  margin-left: 5px !important;
}

.margin-left-10px {
  margin-left: 10px !important;
}

.margin-left-15px {
  margin-left: 15px !important;
}

.margin-left-20px {
  margin-left: 20px !important;
}

.margin-left-24px {
  margin-left: 24px !important;
}

.margin-left-25px {
  margin-left: 25px !important;
}

.margin-left-30px {
  margin-left: 30px !important;
}

.margin-left-45px {
  margin-left: 45px !important;
}

.margin-left-275px {
  margin-left: 275px !important;
}

.margin-top40 {
  margin-top: 40px !important;
}

.margin-top45 {
  margin-top: 45px !important;
}

.margin-top50 {
  margin-top: 50px !important;
}

.margin-top60 {
  margin-top: 60px !important;
}

.margin-top80 {
  margin-top: 80px !important;
}

.margin-top20 {
  margin-top: 20px !important;
}

.margin-top25 {
  margin-top: 25px !important;
}

.margin-top30 {
  margin-top: 30px !important;
}

.margin-top35 {
  margin-top: 35px !important;
}

.margin-top15 {
  margin-top: 15px !important;
}

.margin-top12 {
  margin-top: 12px !important;
}

.margin-top8 {
  margin-top: 8px !important;
}

.margin-top10 {
  margin-top: 10px !important;
}
.margin-top13 {
  margin-top: 13px !important;
}

.margin-top2 {
  margin-top: 2px !important;
}

.margin-top5 {
  margin-top: 5px !important;
}

.margin-bottom20px {
  margin-bottom: 20px !important;
}

.mb-rem04 {
  margin-bottom: 0.4rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-33 {
  margin-bottom: 33px !important;
}

.mb-43 {
  margin-bottom: 43px !important;
}

.pt-33 {
  padding-top: 33px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.padding-35px {
  padding: 35px !important;
}
.padding-top5 {
  padding-top: 5px !important;
}
.padding-top6 {
  padding-top: 6px !important;
}
.padding-top20 {
  padding-top: 20px !important;
}

.padding-tb {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padding-left-5px {
  padding-left: 5px !important;
}

.padding-left-15px {
  padding-left: 15px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

/* input,
button {
  max-width: 29rem !important;
} */

.max-width-100 {
  max-width: 100% !important;
}

.input-search {
  font-family: Helvetica, Arial, sans-serif, FontAwesome !important;
  min-width: 300px;
}

.MuiAutocomplete-input {
  font-family: helvetica, Arial, sans-serif, FontAwesome !important;
}

/* input,
input::-webkit-input-placeholder {
    font-size: 18px;
} */

.upload-photo-label {
  padding: 0 45px;
  color: #2f427c;
  font-weight: bold;
}

.blue-backGround {
  background-color: #2f427c !important;
  color: #fff !important;
  text-align: center;
  padding: 20px 30px;
}

.slider-parent {
  position: relative;
  text-align: center;
  margin-top: 78px;
}

.logo {
  max-width: 120px;
  margin: 10px;
}

.logo_maid {
  max-height: 50px;
  margin: 10px;
  margin-left: 20px;
}

.logo_maid_tip {
  max-height: 52px;
  margin-top: 10px;
}

.logo_maid_hotel_form {
  max-height: 300px !important;
  margin: 10px;
  background-color: #fcfcfc;
}

.rangeslider {
  width: 100% !important;
  max-width: 2000px !important;
}

.ml-40px {
  margin-left: 47px;
}

.maxw1000 {
  max-width: 1000px !important;
}

.tipinfodiv {
  display: flex;
  justify-content: space-between;
}

.tipinfodiv p {
  margin: 0;
}

/* .rangeslider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 23px;
  height: 24px;
  border: 0;
  background: url('contrasticon.png');
  cursor: pointer;
}

.rangeslider::-moz-range-thumb {
  width: 23px;
  height: 24px;
  border: 0;
  background: url('contrasticon.png');
  cursor: pointer;
} */

.socialIconsdiv {
  text-align: center;
  /* position: absolute;
  bottom: 0;
  right:30%; */
}

.socialIconsinnerDiv {
  font-size: 20px;
}

.socialIconsdiv a {
  text-decoration: none;
  margin: 10px;
  color: black;
}

.buble {
  position: absolute;
}

.blue-backGround input::placeholder {
  color: #fff !important;
}

.page-heading {
  color: #676767 !important;
  font-weight: bold !important;
  margin-bottom: 20px !important;
  padding-top: 2px;
}

.page-sub-heading {
  color: #676767;
  padding: 10px 20px 3px 20px !important;
  border-radius: 8px !important;
  border: 1px #dbdfe9 solid;
  font-size: 1rem;
  font-weight: bold;
  background-color: #ebecf4;
}

.page-sub-data {
  padding-top: 15px !important;
  color: #676767;
}

.extra-heading {
  padding-top: 10px;
  color: #676767 !important;
  margin-right: 10px;
}

.box-data {
  color: #676767;
  padding: 5px 15px !important;
  border-radius: 0px !important;
  border: 1px #dbdfe9 solid;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 5px;
}

.box-border-grey {
  border: 1px #cccccc solid !important;
}

.pagination a.active {
  border: 2px solid #676767;
  color: #676767;
}

/* side nav */

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3 !important;
  top: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #dbdfe9;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0 3px 15px rgb(0 0 0 / 50%);
}

.sidenav a.link {
  text-decoration: underline !important;
  color: #2f427c !important;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
    z-index: 3 !important;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.rounded-20px {
  border-radius: 20px !important;
}

label.rounded-circle.active {
  color: #00ba34 !important;
  border: 1px solid #00ba34 !important;
}

input.border-bottom {
  border: 0px !important;
  border-bottom: 1px solid #dddd !important;
}

.border-b {
  border-bottom: 1px solid #dbdfe9;
}
.border {
  border: 1px #e0e0e0 solid;
}

.shadow {
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
}

.green-box {
  background-color: #dff4e6 !important;
  padding: 20px;
  color: #00ba34 !important;
  border: 1px solid #00ba34 !important;
}

.image-box {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddd;
  width: 400px !important;
  height: 400px !important;
  cursor: pointer;
}

.image-box-signature {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddd;
  width: 100%;
  height: 85px !important;
  cursor: pointer;
}

.image-box-signature canvas {
  width: 100% !important;
  height: 100% !important;
}

.image-box-signature img.center_normal {
  width: 100% !important;
  height: 100% !important;
}

.input-error {
  border: 1px solid #d54040 !important;
}

.div-flex-box {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.image-box-idproof {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddd;
  width: 100%;
  height: 275px !important;
  cursor: pointer;
}

.image-box-idproof canvas {
  width: 100% !important;
  height: 100% !important;
}

.image-box-idproof img.center_normal {
  width: 100% !important;
  height: 100% !important;
}

.image-idproof {
  padding: 0px;
  display: flex;
  justify-content: center;
  border: 1px solid #dddd;
  width: 100%;
  height: 275px !important;
  cursor: pointer;
}

.generate-w9 {
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}

.link-text-u {
  text-decoration: underline;
  cursor: pointer;
}

.qr-code-box {
  display: flex;
  justify-content: center;
}

.image-box-qrcode {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddd;
  width: 300px !important;
  height: 300px !important;
}

.image-box-qrcode canvas {
  width: 100% !important;
  height: 100% !important;
}

.image-box img.center_small {
  width: 71px !important;
  height: 60px !important;
}

.image-box img.center_normal {
  width: 100% !important;
  height: 100% !important;
}

.height-between {
  height: 90px !important;
}

.slider-label {
  margin-top: 15px !important;
  font-size: 1rem !important;
}

footer {
  padding: 20px !important;
  color: #bbb;
  text-align: center !important;
  background-color: #f0f0f0 !important;
}

.pay-period {
  color: #676767;
  text-align: center;
  padding-top: 7px;
  font-size: 1.1 rem;
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-link {
  text-decoration: underline;
}

.dropbtn {
  padding: 5px;
  border: none;
  max-width: 20px;
  text-align: center;
  cursor: pointer;
  margin: auto;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: solid #999999 1px;
  border-radius: 0px;
  text-align: center;
  transform: translate3d(-25px, 30px, 0px);
  top: 5px !important;
  left: 37px !important;
}

.dropdown-content a {
  color: black;
  padding: 5px 5px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #dddddd;
}

.sel-box {
  padding: 6px !important;
  background-color: #fff !important;
}

.hide {
  display: none;
}

.nomal-button:hover {
  background-color: #2f427c;
  color: #fff !important;
}

button.csv-button {
  padding: 7px 13px !important;
}

button.delete-button {
  padding: 7px 13px !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.table-loader {
  position: relative;
  min-height: 40vh;
}

.loader-center {
  position: absolute;
  left: 50%;
  top: 50%;
}

.content_container {
  /* max-height: 100vh; */
}

.image-upload > input {
  display: none;
}

a.url-field {
  text-decoration: none !important;
  color: #0d6efd !important;
  cursor: pointer;
}

.btn-flex-row {
  display: flex;
  flex-direction: row;
}

.content-box {
  min-height: 70vh;
}

.stripe-base {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stripe-child {
  text-align: center;
}

.tip-value {
  font-weight: 500;
  font-size: 3rem;
}

.color-green {
  color: #00ba34 !important;
}

.pointer-cursor {
  cursor: pointer;
}

.info-sample {
  height: 24px;
  width: 27px;
}
.info-sample:hover {
  /* background-color:#2878fa; */
  color: white;
}
/* .invert-filter {
  filter: brightness(1) invert(1);
} */

.tel-input {
  padding-left: 48px !important;
  width: 100% !important;
  border: 1px #b1b9ce solid !important;
  font-size: 1rem !important;
}

.tel-input::placeholder {
  color: #707070;
  font-size: 14px;
}

.tel-input:hover {
  border: 1px #2f427c solid;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 2.5rem !important;
  position: relative;
  letter-spacing: 0.01rem;
  border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 1rem !important;
}

.react-tel-input .form-control:focus {
  border: 1px #2f427c solid !important;
  background-color: #ffffff !important;
  outline: none !important;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.react-tel-input .form-control:focus-visible {
  outline-offset: 0 !important;
  outline: none !important;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.selected-flag {
  border: 1px #b1b9ce solid !important;
}

.no-date-table {
  text-align: center;
  min-width: 90vw;
  position: absolute;
}

.react-select-maids__control {
  max-width: 20vw;
  border: 0px !important;
}

.react-select-maids__control--is-focused {
  border: 0px !important;
  box-shadow: 0 0 0 0 !important;
}

.react-select-maids__input {
  width: 100vw !important;
  padding: 8px !important;
  /* border: 1px #b1b9ce solid !important; */
  border-radius: 0px 0 0px 0px !important;
  opacity: 1 !important;
  border: 1px #e3e0e0 solid !important;
  color: #676767 !important;
}

.react-select-maids__menu-list {
  max-height: 50vh !important;
}

.react-select-maids__menu-list .react-select-maids__option--is-selected {
  color: white !important;
}

.react-select-maids__input {
  color: #676767 !important;
}

.react-select-maids__input:focus {
  border: 1px #e3e0e0 solid !important;
}

.react-select-maids__input-container {
  margin: 0 !important;
  padding: 0 !important;
}

.react-select-maids__single-value {
  color: #676767;
  font-size: 18px !important;
  font-weight: bold !important;
  padding-left: 7px !important;
}

.react-select-maids__value-container {
  padding: 0 !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  font-size: 1rem !important;
}

.react-select-maids__indicators {
  /* border: 1px #b1b9ce solid; */
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: 1px #e3e0e0 solid !important;
  border-left: none !important;
}

.react-select-maids__indicator {
  color: #e3e0e0 !important;
}

.react-select-maids__option {
  color: #676767 !important;
}

.react-select-maids__indicator-separator {
  display: none;
}

.maid-list-title {
  padding-top: 6px;
}

.min-height-15 {
  min-height: 130px;
}

.min-height-255 {
  min-height: 255px !important;
}

.min-height-330 {
  min-height: 330px !important;
}

.min-width-300 {
  min-width: 300px !important;
}

#sideImage {
  min-width: 456px;
}

.id-proof-disp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.id-proof-image {
  zoom: 1.5;
}

.store_button {
  max-width: 200px;
  width: 100%;
}

.mt-30px {
  margin-top: 30px !important;
}

img.small {
  max-width: 120px;
  width: 100%;
}

img.headerSmall {
  height: 55px;
}

img.standard {
  width: 100%;
  height: 50%;
  max-width: fit-content;
  max-height: fit-content;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.foot {
  bottom: 0px;
  width: 100%;
}

.inactive-hotel {
  color: red;
  text-align: center;
}

.rows-error {
  background-color: #dc3545;
  color: white;
}

.rows-error-col {
  flex-direction: column;
}

.downoad-qr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-icon {
  width: 15px;
  height: 15px;
}

.maid-list-container {
  display: flex;
  justify-content: space-between;
}

.warning-text {
  color: red;
  font-size: xx-large;
}

.refund-modal {
  background: white;
  padding: 35px;
  width: 100% !important;
  height: 200px;
  display: flex;
  flex-direction: column;
}

.refund-flex-center {
  align-self: center;
}

.refund-margin {
  margin: 10px;
}

.id-display-modal-main {
  background: white;
  width: 75vw;
  height: 700px;
  overflow: auto;
}

.t-align-center {
  text-align: center;
}

.id-image {
  object-fit: contain;
  width: 800px;
  height: 600px;
}

@media only screen and (min-width: 992px) {
  #mainNav {
    border-bottom: 1px solid transparent;
    background: transparent;
  }
}

.slider-close-button {
  margin-top: 15px !important;
  align-self: center !important;
}

.padding-right-12 {
  padding-right: 12px !important;
}

.padding-right-45 {
  padding-right: 45px !important;
}

.padding-top-8 {
  padding-top: 8px !important;
}
.p-10 {
  padding: 10px;
}

.rnc__notification {
  width: 900px !important;
}

.privacypolicyLink {
  position: absolute !important;
  bottom: 10px !important;
  text-align: center !important;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.min-h-half {
  min-height: 50vh !important;
}

.min-h-full {
  min-height: 100vh !important;
}

.bg-white {
  background-color: #fff !important;
}

.max-width-500 {
  max-width: 500px !important;
  width: 100% !important;
}

.max-width-400 {
  max-width: 400px !important;
  width: 100% !important;
}

.idproof-modal {
  background: white;
  padding: 35px;
  width: 100% !important;
  height: 200px;
  display: flex;
  flex-direction: column;
  border: 1px #e0e0e0 solid;
}

.idproof-flex-center {
  align-self: center;
}

.idproof-margin {
  margin: 10px;
}

.maid-login-logo {
  width: 130px !important;
}

.border-red {
  border: 1px solid #f50b0b !important;
}

.maid-profile-logo {
  width: 60px !important;
  margin-top: 10px !important;
  margin-bottom: 7px !important;
}

.min-width-300 {
  min-width: 300px !important;
}

.min-width-125 {
  min-width: 125px !important;
}

.min-width-170 {
  min-width: 170px !important;
}

.min-width-400 {
  min-width: 400px !important;
  max-width: 400px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  text-decoration: underline !important;
}

.cursor-pointer-link {
  cursor: pointer;
}

.cursor-pointer-link:hover {
  text-decoration: underline;
}

.div-center-profile {
  display: inline-block;
  margin: 0 auto;
}

.profile-container {
  right: 0;
  text-align: center;
}

.profile-container .left,
.profile-container .center,
.profile-container .right {
  display: inline-block;
}

.profile-container .left {
  float: left;
}

.profile-container .center {
  margin: 0 auto;
}

.profile-container .right {
  float: right;
}

.temp-border {
  border: #f00a34 1px solid !important;
}

.hidden {
  display: none !important;
}

.width-50-perc {
  width: 50% !important;
}

.width-50-perc {
  width: 50% !important;
}

.disabled {
  background-color: #e6e6e6 !important;
}

.input-icons {
  width: 100%;
  max-width: 35em;
  margin-bottom: 10px !important;
  position: relative;
  margin: 0 auto;
}

.hotel_icon {
  padding: 12px;
  min-width: 40px;
  position: absolute;
  font-size: 17px;
}

.width-100-perc {
  width: 100% !important;
}

.align-center-register {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80vw;
  margin-top: 35px;
}

.align-center-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  min-width: 80vw;
  margin: 35px;
}

.eye-icon {
  padding: 12px;
  min-width: 40px;
  position: absolute;
  font-size: 18px;
  float: right !important;
  color: #404040;
}

.calendar-icon {
  z-index: 9;
  position: absolute;
  right: 5px;
  bottom: -3px;
  padding: 12px;
  min-width: 40px;
  font-size: 18px;
  float: right !important;
  color: #404040;
}

.associate-modal {
  background: white;
  padding: 35px;
  width: 100% !important;
  height: 515px;
  display: flex;
  flex-direction: column;
  border: 1px #e0e0e0 solid;
  cursor: default !important;
}
.needusermerge-modal {
  background: white;
  padding: 35px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  border: 1px #e0e0e0 solid;
  cursor: default !important;
}

.associate-flex-center {
  align-self: center;
}

.associate-margin {
  margin: 10px;
}

.small-red {
  color: red;
  font-size: x-small;
}

.small-text {
  font-size: x-small;
}

.small-text-12 {
  font-size: 12px !important;
}

input.activeCheckbox {
  width: 25px;
  height: 24px;
  margin-right: 3px !important;
}

.border-none {
  border: none !important;
}

.tel-maid-container {
  position: relative;
  padding: 0px !important;
  min-width: 200px !important;
}

.tel-maid {
  height: 100%;
  width: 100%;
  background-color: transparent !important;
}

.tel-input-maid {
  z-index: 10;
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  background-color: transparent !important;
}

.fa-srt {
  font-size: 18px;
  margin-top: 3px;
}

.circlediv {
  position: relative;
  margin-left: 21px;
}

.circle {
  height: 43px;
  width: 44px;
  border-color: #696969 !important;
  /* background-color: #eee !important; */
  background-color: #bbbbbb !important;
  color: #fff !important;
  /* color: #333 !important; */
  border-radius: 50%;
  display: inline-block;
  margin-top: 3px;
}

.circle:hover {
  color: #ffffff !important;
  background: #2878fa !important;
}

.circle .fa-filter {
  position: relative;
  top: 12px;
  left: 14px;
  font-size: 20px;
}

/* side nav */
.show_sidenav {
  width: 33% !important;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #dbdfe9;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav a.link {
  text-decoration: underline !important;
  color: #2f427c !important;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.display-column {
  display: flex;
  flex-direction: column;
}

.bluebackGround {
  background-color: #2f427c !important;
}

.link-resent-m {
  text-decoration: none;
  cursor: pointer;
  color: #676767;
}

.link-resent-m:hover {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.copy-link-button-width {
  min-width: 285px;
  max-width: 285px;
  min-height: 65px !important;
}

.vertical-top {
  vertical-align: top !important;
}

.border-one {
  border: 1px #dbdfe9 solid !important;
}

.css-amamf7-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 0px !important;
}

.autocomplete {
  border-radius: 4px !important;
  border: 1px #b1b9ce solid;
  font-size: 1rem;
  padding: 0px !important;
}

.autocomplete:hover {
  border: 1px #2f427c solid;
}

.autocomplete:focus {
  border: 1px #2f427c solid;
}

.select_checkbox {
  width: 100% !important;
  /* border: none !important; */
}

.select_checkbox fieldset {
  border: none !important;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 2px !important;
  height: 39px !important;
  overflow: scroll;
}
.MuiOutlinedInput-root.MuiInputBase-sizeSmall::-webkit-scrollbar {
  display: none;
}

.css-ptiqhd-MuiSvgIcon-root {
  display: none !important;
}

.hotel-drag-logo {
  width: 151%;
}

.select_checkbox input {
  position: relative;
  border: none !important;
}

.select_checkbox button {
  border: none !important;
  padding: 0px 2px !important;
}

.select_checkbox button:hover {
  border: none !important;
}

.resetpwd-modal {
  background: white;
  padding: 35px;
  width: 85vw;
  height: 225px !important;
  display: flex;
  flex-direction: column;
  border: #999999 1px solid;
  cursor: auto;
}

.resetpwd-flex-center {
  align-self: center;
}

.resetpwd-margin {
  margin: 10px;
}

.resetpwd-text {
  font-size: 13px;
  cursor: pointer !important;
}

.resetpwd-text:hover {
  text-decoration: underline solid 1px !important;
}

.forgotpwd-modal {
  background: white;
  padding: 35px;
  width: 100% !important;
  height: 250px !important;
  display: flex;
  flex-direction: column;
  border: 1px #e0e0e0 solid;
  cursor: default !important;
}

.forgotpwd-text {
  font-size: 14px;
}
.forgotpwd-text:hover {
  text-decoration: underline solid 1px !important;
  cursor: pointer;
}

/* For Drag and Drop */

.drop-file-input {
  position: relative;
  width: 100%;
  height: 257px;
  border: 1px dashed rgb(189, 187, 187);
  border-radius: 20px;
  text-align: center;
  padding: 3px;
  z-index: 1 !important; 
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100% !important;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.8;
}

.drop-file {
  height: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.drop-file-preview {
  position: relative;
  max-width: 45%;
}

.drop-file-preview_img {
  max-width: 650px !important;
  min-height: 75px !important;
  max-height: 250px !important;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin: 3px !important;
}

.drop-file-preview_img.dragover {
  display: none !important;
}
.drop-file.dragover {
  display: none !important;
}

.drop-file-preview-item-del {
  opacity: 0;
  z-index: 999;
}

.drop-file-preview-item-del button {
  cursor: pointer;
  padding: 8px !important;
  transition: opacity 0.3s ease;
  position: absolute;
  top: -4px;
  right: -19px;
  border-radius: 50px !important;
  background-color: rgb(209, 204, 204);
  box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.3);
  border: none !important;
}

.drop-file-preview-item-del button:hover {
  background-color: rgb(209, 204, 204) !important;
  border: none !important;
  color: rgb(71, 69, 69);
}

.drop-file-preview:hover .drop-file-preview-item-del {
  opacity: 1;
}

.MuiCardActionArea-root {
  padding: 0px !important;
  border: none !important;
}

.ReactCrop__crop-selection {
  border: 1px dashed blue !important;
}

.ReactCrop__drag-handle::after {
  border: 1px solid lightblue !important;
}

.movetoinboxicon {
  margin-top: 70px !important;
  font-size: 42px !important;
  color: #676767 !important;
}

.crophead {
  background-color: #1e3343 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: white !important;
}

.cropbody {
  /* background-color: white !important; */
  padding: 5px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  border-bottom: 1px solid rgb(234, 233, 233);
  height: 450px !important;
  width: 100% !important;
}

.ReactCrop {
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 0.5px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;
}

.cropimage {
  max-width: 100% !important;
  min-height: 75px !important;
  max-height: 440px !important;
}

.cropaction {
  padding: 15px !important;
  border: none !important;
  float: right !important;
}

.cropcanvas {
  width: 0px !important;
  height: 0px !important;
  position: absolute !important;
  top: 0px !important;
  z-index: -9 !important;
  opacity: 0 !important;
  visibility: hidden;
}

/* For Drag and Drop */

.csvmain {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 69px;
}

.align-csv {
  display: flex;
  justify-content: space-between;
}
.CsvDropdown {
  position: absolute;
  top: 48px;
  right: 0px;
  width: 209px;
  background-color: white;
  color: #000;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  border-radius: 0px;
}
.CsvDropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.CsvDropdown li {
  padding: 10px 16px;
  border-bottom: 1px solid rgb(233, 226, 226);
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.CsvDropdown ul li:first-child:hover {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.CsvDropdown ul li:last-child:hover {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.header-top-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: normal;
  align-items: center;
  z-index: 2;
}

.font-16{
  font-size: 16px !important;
}

.red-text {
  color: red !important;
}

.red-border {
  border: 1px solid red !important;
}

