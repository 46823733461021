.logout {
  bottom: 1%;
  top: 15vh;
  position: relative;
  padding: 5%;
  font-size: 14px;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  /* border-top: 1px solid grey; */
  box-shadow: 0 -2px 5px -5px rgba(0, 0, 0, 0.3);
}
.logout > .h4 {
  margin: 0;
}
.user-profile {
  padding-top: 15px;
  color: #0a004d;
}
.NavDropdown{
  position: absolute;
  top: 114%;
  right: 39px;
  width: 250px;
  background-color: #fff;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  margin-top: -12px !important;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 10px 16px;
  border-bottom: 1px solid rgb(233, 226, 226);
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.NavDropdown ul li:first-child:hover {
border-top-left-radius: 8px;
border-top-right-radius: 8px;
}
.NavDropdown ul li:last-child:hover {
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
}